const Endpoints = {
  production: {
    image_endpoint: "https://reservegoappicons.s3.ap-south-1.amazonaws.com",
    api_endpoint: "https://api.reservego.co/api",
  },
  development: {
    image_endpoint: "https://reservegoappicons.s3.ap-south-1.amazonaws.com",
    api_endpoint: "https://stagingapi.reservego.co/api",
  },
};
export const ENDPOINTS =  Endpoints["production"] || {};

export const IMAGE_ENDPOINT = ENDPOINTS.image_endpoint;
